import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * 標示為安全的url
 *
 * @export
 * @class SafeUrlPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'SafeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) { }

  transform(value: any, args?: any): any {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }

}
