import { Pipe, PipeTransform } from '@angular/core';

type siteKey = string;

/**
 * 將標籤的 key 轉換為 name
 */
@Pipe({
  name: 'convertsLabelKeyToName',
})
export class ConvertsLabelKeyToNamePipe implements PipeTransform {

  constructor() {
  }

  transform(labelKey: string, args: [siteKey]): any {
    return labelKey.replace(/_/, '/');
  }

}
