import { Directive, Input, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject, interval, timer, iif, Observable } from 'rxjs';
import { mergeMap, takeUntil, take, map, tap, finalize, startWith } from 'rxjs/operators';


/**

 dynamicPlaceholder 可傳入要顯示的 placeholer 文字陣列
  preText: 前綴修飾文字
  stay: 每一個 placeholder 文字間的間隔時間(ms) 預設值 1000
  speed: 顯示速度 (ms) 預設值 100

 */
@Directive({
  selector: 'input[dynamicPlaceholder]'
})
export class DynamicPlaceholderDirective implements AfterViewInit, OnDestroy {
  @Input('dynamicPlaceholder') placeholers: string[] = [];;
  @Input() preText = '';
  @Input() stay = 1000;
  @Input() speed = 100;

  inputElement = this.el.nativeElement;
  startPoint$ = new Subject<string>();
  destroy$ = new Subject();
  forward = true;
  private idx = -1;

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {

    const getNextPlaceHolder = () => {
      if (this.forward) {
        this.idx++;
      }
      return this.placeholers[this.idx % this.placeholers.length];
    };

    const goForward = (placeholder: string) => interval(this.speed).pipe(
      take(placeholder.length),
      map((idx) => placeholder.substring(0, idx + 1)),
      finalize(() => this.startPoint$.next(getNextPlaceHolder()))
    );

    const goReverse = (placeholder: string) => timer(this.stay, this.speed).pipe(
      take(placeholder.length),
      map((idx) => placeholder.substring(0, placeholder.length - idx)),
      finalize(() => this.startPoint$.next(getNextPlaceHolder()))
    );

    if (this.placeholers) {
      this.startPoint$.pipe(
        startWith(getNextPlaceHolder()),
        takeUntil(this.destroy$),
        mergeMap((placeholder) => iif(() => this.forward = !this.forward, goReverse(placeholder), goForward(placeholder))),
      ).subscribe(placeholder => this.inputElement.placeholder = `${this.preText} ${placeholder}`);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
