import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appIsNgForLast]'
})
export class IsNgForLastDirective {

  constructor() { }
  @Input()
  set appIsNgForLast(data: any) {
    if (typeof (data.fn) === 'function' && data.isLast) {
       data.fn();
      // console.log('isLast');
    }
  }

}
