import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
// import { HttpModule } from '@angular/http';
import { RemoveHtmlTagPipe } from './pipe/RemoveHtmlTag.pipe';
import { DecodeHtmlPipe } from './pipe/decodeHtml.pipe';
import { SafeHtmlPipe } from './pipe/SafeHtmlPipe.pipe';
import { SafeUrlPipe } from './pipe/SafeUrlPipe.pipe';
import { DataFilterPipe } from './pipe/data-filter.pipe';
import { DatexPipe } from './pipe/datexPipe.pipe';
import { SortedByPipe } from './pipe/sorted-by.pipe';
import { TextLengthPipe } from './pipe/textLengthPipe.pipe';
import { DecodeURIComponentPipe } from './pipe/decodeURIComponent.pipe';
import { TransformLinkHtmlPipe } from './pipe/transform-link-html.pipe';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { IsNgForLastDirective } from './directive/isNgForLast.directive';
import { ANoneHashDirective } from './directive/a-none-hash.directive';
import { DynamicPlaceholderDirective } from './directive/dynamic-placeholder.directive';
import { SharedService } from './shared.service';
import { ToNumberPipe } from './pipe/toNumber.pipe';
import { AdminService } from './services/admin.service';
// import { UnitLabelService } from '../console/shared/component/unit-label/unit-label.service';
import { CoreModule } from './modules/core.module';
import { ReplaceTextPipe } from './pipe/replace-text.pipe';
import { ConvertsLabelKeyToNamePipe } from './pipe/converts-label-key-to-name.pipe';
import { InputFileDirective } from './directive/input-file.directive';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // HttpModule,
    CoreModule
  ],
  declarations: [
    RemoveHtmlTagPipe,
    DecodeHtmlPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    DataFilterPipe,
    DatexPipe,
    SortedByPipe,
    TextLengthPipe,
    ToNumberPipe,
    DecodeURIComponentPipe,
    TransformLinkHtmlPipe,
    ReplaceTextPipe,
    ConvertsLabelKeyToNamePipe,
    IsNgForLastDirective,
    ANoneHashDirective,
    DynamicPlaceholderDirective,
    InputFileDirective,
  ],
  exports: [
    RemoveHtmlTagPipe,
    DecodeHtmlPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    DataFilterPipe,
    DatePipe,
    SortedByPipe,
    TextLengthPipe,
    ToNumberPipe,
    DecodeURIComponentPipe,
    TransformLinkHtmlPipe,
    ReplaceTextPipe,
    ConvertsLabelKeyToNamePipe,
    DatexPipe,
    FormsModule,
    // HttpModule,
    ReactiveFormsModule,
    IsNgForLastDirective,
    ANoneHashDirective,
    DynamicPlaceholderDirective,
    InputFileDirective,
  ],
  providers: [
    DatePipe,
    DatexPipe,
    DecodeURIComponentPipe,
    FormBuilder,
    IsNgForLastDirective,
    ANoneHashDirective,
    DynamicPlaceholderDirective,
    InputFileDirective,
  ],
})
export class SharedModule {
  static forRoot(providers = []): ModuleWithProviders<SharedModule> {
    return <ModuleWithProviders<SharedModule>>{
      ngModule: SharedModule,
      providers: [
        ...providers,
        SharedService,
        AdminService,
        // UnitLabelService,
        // 放service
      ]
    };
  }
}
