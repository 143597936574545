import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {

  transform(array: any[], query: string): any {
    if (query) {
      return _.filter(array,
        row => {
          let isFilter = false;
          for (const k in row) {
            if (row[k].toString().indexOf(query) > -1) {
              isFilter = true;
            }
          }
          return isFilter;
        }
      );
    }
    return array;
  }
}
