import { InjectionToken, Provider } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export const GET_SITE = new InjectionToken<string>(
  'get site'
);

export const GET_SITE_PROVIDER: Provider = {
  provide: GET_SITE,
  deps: [ActivatedRoute],
  useFactory: getSiteFactory,
};

export function getSiteFactory(
  route: ActivatedRoute,
): string {
  const site = `${window.location.pathname.split('/')[2] || ''}`;
  return site;
}
