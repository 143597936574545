import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ENV_CONFIG } from '../interface/firebase-env';
import { environment } from 'src/environments/environment';
// import { FirebaseModule } from './firebase.module';
import { GET_SITE_PROVIDER } from './providers/get-site.provider';
import { CONSOLE_SHARED_PROVIDER } from './providers/console-shared.provider';
import { DeveloperFirebaseModule } from './firebase-developer.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // FirebaseModule,
    // DeveloperFirebaseModule,
  ],
  providers: [
    { provide: ENV_CONFIG, useValue: environment },
    GET_SITE_PROVIDER,
    CONSOLE_SHARED_PROVIDER,
  ]
})
export class CoreModule { }
