import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

/**
 * 用以解決IE11 日期pipe顯示問題
 *
 * @export
 * @class DatexPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'datex'
})
export class DatexPipe implements PipeTransform {
  transform(value: any, format: string = ''): string {
    // Try and parse the passed value.
    // const momentDate = moment.tz(value, 'Asia/Taipei');
    const momentDate = moment(value);

    // If moment didn't understand the value, return it unformatted.
    if (!momentDate.isValid()) {
      return value;
    } else {
      // Otherwise, return the date formatted as requested.
      return momentDate.format(format);
    }
  }
}
