import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
// import { Http, Headers, RequestOptions } from '@angular/http';
// import { isUndefined } from 'util';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable, lastValueFrom } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import * as firebase from 'firebase/app';
import 'firebase/functions';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { getFunctions, httpsCallable } from 'firebase/functions';

@Injectable()
export class SharedService {

  public progress = 0;
  private emitChangeSource: Subject<any>; // Observable string sources
  private changeEmitted$: Observable<any>; // Observable string streams
  // downloadMultiSheetsXlsxOnCall = firebase.functions().httpsCallable('downloadMultiSheetsXlsxOnCall');
  functions = getFunctions();
  downloadMultiSheetsXlsxOnCall = httpsCallable(this.functions, 'downloadMultiSheetsXlsxOnCall');

  constructor(
    public http: HttpClient,
    public httpClient: HttpClient,
    public dialog: MatDialog,
    public afdb: AngularFireDatabase,
    public afstore: AngularFirestore,
    public storage: AngularFireStorage,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.emitChangeSource = new Subject();
    this.changeEmitted$ = this.emitChangeSource.asObservable();
  }

  // Service message commands
  public emitChange(change: any) {
    this.emitChangeSource.next(change);
  }


  /*
  上傳檔案至firebase storage

  file(必要):檔案
  folder(必要):storage的目錄
  filename(可選):上傳後的檔案名稱
   */
  public uploadFile(file: File, folder: string, filename?: string ): Promise<{
    downloadURL: string,
    metadata: {
      size: number,
      updated: string,
      contentType: string,
      bucket: string,
      type: string,
      fullPath: string;
    }
  }> {
    return new Promise((res, rej) => {
      const fileName = `${filename || ''}`.replace(/[\(\)\']/g, '') ?
        `${filename || ''}`.replace(/[\(\)\']/g, '') :
        Date.now().toString() + '-' + file.name.replace(/[\(\)\']/g, '');
      const task = this.storage.upload(`/${folder}/${fileName}`, file);
      const fileRef = this.storage.ref(`/${folder}/${fileName}`);
      task.percentageChanges().subscribe(snapshot => {
        if (snapshot === undefined) {
          return;
        }
        this.progress = parseFloat(snapshot.toFixed(2));
      });

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(downloadURL => {
            fileRef.getMetadata().subscribe(Metadata => {
              res({ 'downloadURL': downloadURL, metadata: Metadata });
            });
          });
        })
      ).subscribe();

    });
  }


  /*
    上傳blob至firebase storage
    blob(必要):Blob
    folder(必要):storage的目錄
    filename(可選):上傳後的檔案名稱
  */
  public uploadBlob(
    blob: Blob,
    folder: string,
    filenameExtension: string,
    filename?: string
  ): Promise<{
    downloadURL: string,
    metadata: {
      size: number,
      updated: string,
      contentType: string,
      bucket: string,
      type: string,
      fullPath: string;
    }
  }> {
    return new Promise((res, rej) => {
      const fileName = filename
        ? filename
        : Date.now().toString() + '.' + filenameExtension;
      const fileRef = this.storage.ref(`/${folder}/${fileName}`);
      const task = fileRef.put(blob);

      task.percentageChanges().subscribe((snapshot) => {
        if (snapshot === undefined) {
          return;
        }
        this.progress = parseFloat(snapshot.toFixed(2));
      });

      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe((downloadURL) => {
              fileRef.getMetadata().subscribe((Metadata) => {
                res({ downloadURL: downloadURL, metadata: Metadata });
              });
            });
          })
        )
        .subscribe();
    });
  }


  /*
    Resize 圖片檔案

    file(必要):檔案物件
    targetWidth(必要):resize的寬度
  */
  public reszieImageFile(
    originalFile: File,
    targetWidth: number,
  ): Promise<File> {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (fileReader) => {
        if (fileReader.target === null ) {
          return;
        }
        const dataURL = fileReader.target.result as string;
        const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
        const img = new Image();
        img.onload = () => {
          const widthOfOutput = targetWidth;
          const ratioOfOutputWidth = img.width / widthOfOutput;
          const heightOfOutput = img.height / ratioOfOutputWidth;
          canvas.width = widthOfOutput;
          canvas.height = heightOfOutput;
          const contextOfCanvas = canvas.getContext('2d');
          contextOfCanvas?.drawImage(img, 0, 0, widthOfOutput, heightOfOutput);
          canvas.toBlob(async (blob) => {
            if (blob === null) {
              throw new Error('Blob 是 null，無法創建文件');
            }
            const newFile = new File([blob], originalFile.name);
            res(newFile);
          }, originalFile.type);
        };
        img.onerror = (error) => {
          rej(error);
        };
        img.src = dataURL;
      };
      reader.onerror = (error) => {
        rej(error);
      };
      reader.readAsDataURL(originalFile);
    });
  }


  /**
   * 移除storage中的檔案
   * @param path 檔案路徑
   */
  public removeFile(path: string) {
    return Promise.resolve(this.storage.ref(path).delete());
  }

  /**
   * 移除html tag
   * @param strText
   */
  public RemoveHtmlTag(strText: string): string {
    return strText.replace(/<[^>]*>/g, '');
  }


  public decodeHtml(strText: string): string {
    if (!strText) {
      return "";
    } else {
      const txt = document.createElement('textarea');
      txt.innerHTML = strText;
      return txt.value;
    }
  }

  /**
   * 向左補0
   * @param str
   * @param lenght
   */
  public padLeft(str: string, lenght: number): string {
    if (str.length >= lenght) {
      return str;
    } else {
      return this.padLeft('0' + str, lenght);
    }
  }

  /**
   * ajax方法
   * @param url
   * @param data
   */
  ajaxPost(url: string, data: any, type?: 'application/json' | 'application/x-www-form-urlencoded') {
    let headers: HttpHeaders;
    // let options: RequestOptions;
    let body: any;
    switch (type) {
      case 'application/json':
        headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8' });
        // return this.http.post(url, data, options).toPromise();
        body = JSON.stringify(data);
        break;

      case 'application/x-www-form-urlencoded':
      default:
        // headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' });
        // options = new RequestOptions({ headers: headers });
        // const urlSearchParams = new URLSearchParams();

        // for (const name in data) {
        //   if (data[name] !== undefined) {
        //     urlSearchParams.set(name, data[name]);
        //   }
        // }

        // return this.http.post(url, urlSearchParams.toString(), options).toPromise();
        headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' });
        const urlSearchParams = new URLSearchParams();
        for (const name in data) {
          if (data.hasOwnProperty(name) && data[name] !== undefined) {
            urlSearchParams.set(name, data[name]);
          }
        }
        body = urlSearchParams.toString();
        break;
      }
      // Convert the Observable returned by http.post() to a Promise using lastValueFrom().
      return lastValueFrom(this.http.post(url, body, { headers }));
  }

  /**
   * 提交動態表單
   * @param url
   * @param data
   */

  submitDynamicForm(url: string, data: any) {
    const formName = 'DynamicForms';

    const form = document.createElement('FORM') as HTMLFormElement;
    form.name = formName;
    form.action = url;
    form.method = 'POST';

    const formInput: HTMLInputElement[] = [];

    let count = 0;
    _.forEach(data, (item, name) => {
      formInput[count] = document.createElement('INPUT') as HTMLInputElement;
      formInput[count].type = 'HIDDEN';
      formInput[count].name = name;
      formInput[count].value = item.toString();
      form.appendChild(formInput[count]);
      ++count;
    });

    document.body.appendChild(form);
    form.submit();
    document.querySelector(`form[name=${formName}]`)!.remove();

  }


  /**
   * 將指定連結分享到FB
   * @param {string} [url]
   * @memberof SharedService
   */
  public shareFB(url?: string): void {
    const shareUrl = url!==undefined ? url : window.location.href;
    FB.ui({
      method: 'share',
      display: 'popup',
      href: shareUrl
    }, function (response: any) { });
  }

  /**
   * 將指定連結分享到LINE
   * @param {string} [url]
   * @memberof SharedService
   */
  public shareLine(url?: string): void {
    const shareUrl = url!==undefined ? url : window.location.href;
    window.open('https://lineit.line.me/share/ui?url=' + encodeURIComponent(shareUrl));
  }

  /**
   * 將指定連結分享到Google plus
   * @param {string} [url]
   * @memberof SharedService
   */
  public shareGoogle(url?: string): void {
    const shareUrl = url!==undefined ? url : window.location.href;
    window.open('https://plus.google.com/share?url=' + encodeURIComponent(shareUrl));
  }


  /**
   * 向db取得list資料
   * @param {string} path 資料表路徑
   * @returns Observable<any[]>
   * @memberof SharedService
   */
  public getList(path: string) {
    return this.afdb.list(path).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ _key: c.payload.key, ...c.payload.val() as any }))
      )
    );
  }

  /**
   * 向db取得object資料
   * @param {string} path 資料表路徑
   * @returns Observable<any[]>
   * @memberof SharedService
   */
  public getObject(path: string) {
    return this.afdb.object(path).snapshotChanges().pipe(
      map(changes =>
        changes.payload.val()
      )
    );
  }


  /**
   * 向firestore取得list資料,並於資料列中自帶id
   * @param {string} path collection路徑
   * @returns Observable<any[]>
   * @memberof SharedService
   */
  public getAFList(path: string): Observable<any[]> {
    return this.afstore.collection(path).snapshotChanges().pipe(
      map(changes =>
        changes.map((c, i) => ({
          _key: c.payload.doc.id,
          _index: i,
          ...c.payload.doc.data() as any
        }))
      )
    );
  }

  /**
   * 向firestore取得list資料,並於資料列中自帶id
   * @param {string} path collection路徑
   * @returns Observable<any[]>
   * @memberof SharedService
   */
  public getAFListOrder(path: string): Observable<any[]> {
    return this.afstore.collection(path, ref => ref.orderBy('createdAt', 'desc').limit(10000)).snapshotChanges().pipe(
      map(changes =>
        changes.map((c, i) => ({
          _key: c.payload.doc.id,
          _index: i,
          ...c.payload.doc.data() as any
        }))
      )
    );
  }

  /**
   * 向firestore取得object資料
   * @param {string} path collection路徑
   * @param {string} key doc key 值
   * @returns Observable<any[]>
   * @memberof SharedService
   */
  public getAFObject(path: string, key: string): Observable<any> {
    return this.afstore.collection(path).doc(key).snapshotChanges().pipe(
      map(changes =>
        changes.payload.data()
      )
    );
  }


  /**
   * 設定meta 並呼叫saveMeta()儲存至DB
   * @param data {key:value}
   */
  public setMeta(data: any) {
    for (const property in data) {
      if (property.indexOf('title') >= 0) {
        this.titleService.setTitle(data[property]);
        this.metaService.updateTag({ property: property, content: data[property] });
      } else {
        this.metaService.updateTag({ property: property, content: data[property] });
      }
    }
    this.saveMeta(data);
  }

  /**
   * 儲存meta資料至DB
   * @param data {key:value}
   */
  public saveMeta(data: any) {
    // const oneMeta = {};
    const oneMeta: Record<string, string> = {};
    for (const key of _.keys(data)) {
      oneMeta[key] = this.RemoveHtmlTag(data[key]);
    }
    this.afdb.object('meta/' + encodeURIComponent(btoa(window.location.href))).update(oneMeta);
  }


  /**
   *  下載excel檔案
   *  將陣列透過後端處理成單頁的excel檔案
   * @param {(Array<string | number>)} title 標題列名稱
   * @param {Array<number>} width 標題列寬度
   * @param {(Array<Array<string | number>>)} data 二維資料陣列
   * @memberof SharedService
   */
  downLoadExcel(title: Array<string | number>, width: Array<number>, data: Array<Array<string | number>>, fileName?: string) {
    this.submitDynamicForm(`${environment.cloudfunctions}/download_xlsx`, {
      title: JSON.stringify(title),
      title_width: JSON.stringify(width),
      data: JSON.stringify(data),
      fileName: fileName || 'data'
    });
  }


  /**
 * ajax方法
 * @param url
 * @param data
 */
   httpClientPost<T = any>(url: string, data: any, apikey?: string){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if (apikey) {
      headers = headers.append('x-api-key', apikey);
    }

    return this.httpClient.post<T>(url, data,  {headers: headers,observe: 'response'});
  }

    /**
 * ajax delete方法
 * @param url
 * @param data
 */
     httpClientDelete<T = any>(url: string, data: any, apikey?: string) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8'
      });

      if (apikey) {
        headers = headers.append('x-api-key', apikey);
      }

      return this.httpClient.request<T>('delete', url, { body:data,observe: "response", headers: headers }) as any as  Observable<HttpResponse<T>>;
    }

  /**
   *  下載excel檔案 OnCall版本
   *  將陣列透過後端處理成單頁的excel檔案
   * @param {(Array<string | number>)} title 標題列名稱
   * @param {Array<number>} width 標題列寬度
   * @param {(Array<Array<string | number>>)} data 二維資料陣列
   * @param fileName
   * @param folderName
   * @memberof SharedService
   */
  async downLoadExcelOnCall(
    title: Array<string | number>,
    width: Array<number>,
    data: Array<Array<string | number>>,
    fileName?: string,
    folderName?: string
  ): Promise<{
    downloadUrl: string,
    fileName: string
  }> {
    const result = await this.httpClient.post(
      "environment.gke.excelDownload", // 此處未搬移，未來可有弄好 API 後可新增環境變數
      {
        title: title,
        title_width: width,
        data: data,
        fileName: fileName || 'report',
        folderName
      }).toPromise() as { fileUrl: string };

    return {
      downloadUrl: result.fileUrl,
      fileName: `${fileName || 'report'}.xlsx`
    };
  }

  /**
   *  下載excel檔案 OnCall版本
   *  將陣列透過後端處理成多頁的excel檔案
   * @param {(Array<string | number>)} title 標題列名稱
   * @param {Array<number>} width 標題列寬度
   * @param {(Array<Array<string | number>>)} data 二維資料陣列
   * @memberof SharedService
   */
  async downLoadMultipleExcelOnCall(
    sheets: {
      sheetName?: string;
      title: Array<string | number>;
      width: Array<number>;
      data: Array<Array<string | number>>;
    }[],
    fileName?: string
  ): Promise<{
    downloadUrl: string;
    fileName: string
  }> {

    const result: any = await this.httpClient.post(
      `${environment.cloudrun.consoleTool}/download-multiple-xlsx`,
      {
        sheets,
        fileName: fileName || 'report'
      }).toPromise();

    return {
      downloadUrl: result.fileUrl,
      fileName: `${fileName || 'report'}.xlsx`
    };
  }


  /**
   *  下載csv檔案 OnCall版本
   *  將陣列透過後端處理成單頁的csv檔案
   * @param {(Array<Array<string | number>>)} data 二維資料陣列
   * @param fileName
   * @param folderName
   * @memberof SharedService
   */
  async downLoadCSVOnCall(
    data: Array<Array<string | number>>,
    fileName?: string,
    folderName?: string
  ): Promise<{
    downloadUrl: string,
    fileName: string
  }> {

    const result: any = await this.httpClient.post(
      `${environment.cloudrun.api}/download-csv`,
      {
        data: data,
        fileName: fileName || 'report',
        folderName,
      }).toPromise();

    return {
      downloadUrl: result.fileUrl,
      fileName: `${fileName || 'report'}.xlsx`
    };
  }

  /**
   *  下載excel檔案 OnCall版本
   *  將陣列透過後端處理成多頁的excel檔案
   * @param {(Array<string | number>)} title 標題列名稱
   * @param {Array<number>} width 標題列寬度
   * @param {(Array<Array<string | number>>)} data 二維資料陣列
   * @memberof SharedService
   */
  downLoadMultiSheetsXlsxOnCall(
    sheetNameArray: Array<string | number>,
    titleArray: Array<Array<string | number>>,
    titleWidthArray: Array<Array<number>>,
    dataArray: Array<Array<Array<string | number>>>,
    fileName?: string
  ): Promise<{
    downloadUrl: string,
    fileName: string
  }> {
    return new Promise((resolve, reject) => {
      this.downloadMultiSheetsXlsxOnCall({
        sheetNameArray: sheetNameArray,
        titleArray: titleArray,
        titleWidthArray: titleWidthArray,
        dataArray: dataArray,
        fileName: fileName || 'report'
      }).then(result => {
        // resolve({
        //   downloadUrl: result.data,
        //   fileName: `${fileName || 'report'}.xlsx`
        // });
        if (typeof result.data === 'string') {
          resolve({
            downloadUrl: result.data,
            fileName: `${fileName || 'report'}.xlsx`
          });
        } else {
          reject(new Error('Expected string for result.data'));
        }
      }).catch(err => {
        reject(err);
      });
    });
  }



}
