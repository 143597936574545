import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'textlength'
})
export class TextLengthPipe implements PipeTransform {

  constructor() { }

  transform(value: any, args?: any): any {
    let text = value as String;
    if (args && !isNaN(args)) {
      if (text.length >= args) {
        text = text.slice(0, args) + '...';
      }
    }
    return text;
  }

}
