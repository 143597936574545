import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 * @export
 * @class DecodeURIComponent
 * @implements {PipeTransform}
 */

@Pipe({
  name: 'decodeURIComponent'
})
export class DecodeURIComponentPipe implements PipeTransform {

  constructor() { }

  transform(value: any, args?: any): any {
    return decodeURIComponent(value);
  }

}
