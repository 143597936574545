import * as _ from 'lodash';
import { getEnvironmentBase } from './environment-base';

export const childEnvironment = {
  cloudfunctions: 'https://asia-east2-aiii-bot-platform.cloudfunctions.net', // 與 shard service 下載 xlsx 相關，目前未搬移，暫時維持原樣
  urlShortenerBaseLoginUrl: {
    base: 'https://stage-nn.aiii.ai',
    default: 'https://stage-nn.aiii.ai/r',
  },
  firebase: {
    apiKey: 'AIzaSyD6cchlu8BP37ycABAu3WkAFvPsVq7G2bw',
    authDomain: 'nn-platform-stage.firebaseapp.com',
    projectId: 'nn-platform-stage',
    storageBucket: 'nn-platform-stage.appspot.com',
    messagingSenderId: '824830670374',
    appId: '1:824830670374:web:c81637da81fb29c812d8e7',
  },
  cloudrun: {
    platformPush: 'https://platform-push-v1-dnz3lqp74q-de.a.run.app', // TODO: Clement
    platformPushEndpoint: 'https://api-gateway-push-dnz3lqp74q-de.a.run.app', // TODO: Clement
    platformPushAnalytics: 'https://platform-push-analytics-dnz3lqp74q-de.a.run.app', // TODO: Clement
    audience: 'https://internal-api.aiii.ai/audience', // TODO: Clement
    api: 'https://nn-cloud-run-stage-gqpo7cryfa-de.a.run.app',
    label: 'https://stage-nn-api.aiii.ai/label',
    urlShortener: 'https://url-shortener-v3-dnz3lqp74q-de.a.run.app',
    adminLog: 'https://internal.aiii.ai/v1/logToBigQuery', // 預期與 bigQuery 相關，暫時維持原樣
    apiProd: 'https://s.aiii.ai/api', // 與權限管理相關，目前未搬移，暫時維持原樣
    asyncMember: 'https://followers-async-dnz3lqp74q-an.a.run.app', // 與用戶管理相關，目前未搬移，暫時維持原樣
    consoleTool: 'https://api-console-tool-dnz3lqp74q-de.a.run.app', // 與 shard service 下載 xlsx 相關，目前未搬移，暫時維持原樣
    newsletter: 'https://internal-api.aiii.ai/newsletter', // 與建立站台快訊相關，目前未搬移，暫時維持原樣
  },
  externalUrl: {
    customerAdminV2: 'https://liff.aiii.ai/customer/customer-admin-v2', // 與客服相關（shared service 內），目前未搬移，暫時維持原樣
  },
  production: false, // 舊公版未註解清楚此用途，但許多檔案有使用此參數，暫時維持原樣
  logV2Config: {
    // 預期與 bigQuery 相關，暫時維持原樣
    projectId: 'aiii-core',
  },
  defaultAdminSite: 'obesityCarePsp',
};

export const environment = _.extend(childEnvironment, getEnvironmentBase());
