import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'toNumber'
})
export class ToNumberPipe implements PipeTransform {

  constructor() { }

  transform(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

}
