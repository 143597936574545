<div class="wrap">
  <div class="login">
    <a id="login" routerLink="/login">Login</a>
  </div>
  <div id="vedio">
    <video class="web play" loop muted autoplay onloadedmetadata="this.muted = true">
      <source src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/video/banner_web.mp4">
    </video>
    <div class="phone play">
        <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/video/video_phone.png">
    </div>
  </div>
  <div class="banner web">
  </div>
  <div class="banner phone">
  </div>

  <div id="service">
    <div class="box">
      <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/service3.png" class="serviceImg">
    </div>
  </div>
  <div id="item1">
    <div class="box">
      <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/item1_num.png" class="numImg">
      <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/item1_phone.png" class="phoneImg">
    </div>
  </div>
  <div id="item2">
    <div class="box">
      <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/item2_title.png" class="title2Img">
      <div class="article">
        <ul>
          <li>
            <div class="pic">
              <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/item2_1.jpg" alt="">
            </div>
            <div>
              <h3 class="title">AI 分析技術</h3>
              <p class="p">結合圖像辨識分析、文字斷詞斷義、推播後結果分析，獨家開發的AI模型，以達到自動化受眾建議，AI比使用者更了解他自己。</p>
            </div>
          </li>
          <li>
            <div class="pic">
              <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/item2_2.jpg" alt="">
            </div>
            <div>
              <h3 class="title">用戶蹤跡全掌握</h3>
              <p class="p">從加入到與Line@互動歷程全追蹤，了解最全面的用戶profile。</p>
            </div>
          </li>
          <li>
            <div class="pic">
              <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/item2_3.jpg" alt="">
            </div>
            <div>
              <h3 class="title">推播成效即時分析</h3>
              <p class="p">如廣告系統般強大，直覺化呈現比較各推播數據成效。</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div id="item3">
    <div class="box">
      <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/item3_title.png" class="title3Img">
      <div class="article">
        <ul>
          <li>
            <div class="pic">
              <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/item3_1.jpg" alt="">
            </div>
            <div>
              <h3 class="title">
                全通路線上下整合
              </h3>
              <p class="p">
                結合線上與線下的歷程追蹤，整合全通路資源，完美串接無斷層！
              </p>
            </div>
          </li>
          <li>
            <div class="pic">
              <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/item3_2.jpg" alt="">
            </div>
            <div>
              <h3 class="title">人流監測分析</h3>
              <p class="p">通過WIFI訊號探測，即時分析人流，了解商圈特性。
                不用開啟WIFI也探測得到！</p>
            </div>
          </li>
          <li>
            <div class="pic">
              <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/item3_3.jpg" alt="">
            </div>
            <div>
              <h3 class="title">精準定位訊息推播</h3>
              <p class="p">善用WIFI的定位特點，精準區分店內外客推播不同訊息內容。
                不用開啟WIFI也可發送訊息！</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div id="MM">
    <div class="box">
      <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/item4_title.png" class="item4_title">
      <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/item4_1.png" class="item4Img">
      <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/qrcode/botplatform_D.png" class="qrcode">
    </div>
  </div>
  <div class="logo">
    <div class="box">
      <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/logo1.png" alt="" class="logo1 web">
      <img src="https://storage.googleapis.com/aiii-bot-platform-tw/report/2018/logo1_phone.png" alt="" class="logo1 phone">
    </div>
    <p class="footer"><span class="pblock">類神經網路 |</span><span class="pblock">
        Ai Marketing Company |</span><span class="pblock"> Aiii</span></p>
  </div>
</div>
