import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from './../shared.service';

@Pipe({
  name: 'RemoveHtmlTag'
})
export class RemoveHtmlTagPipe implements PipeTransform {

  constructor(public ssvc: SharedService) { }

  transform(value: any, args?: any): any {
    return this.ssvc.RemoveHtmlTag(value);
  }

}
