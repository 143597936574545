import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from './../shared.service';

@Pipe({
  name: 'decodeHtml'
})
export class DecodeHtmlPipe implements PipeTransform {

  constructor(public ssvc: SharedService) { }

  transform(value: any, args?: any): any {
    return this.ssvc.decodeHtml(value);
  }

}
