import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import 'firebase/functions';
import { Auth, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, EmailAuthProvider, linkWithCredential, sendPasswordResetEmail } from 'firebase/auth';
import { GoogleAuthProvider, signInWithPopup, linkWithPopup, signOut, signInWithCustomToken, User as FirebaseUser } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getAuth, authState } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Observable, of, OperatorFunction, EMPTY, firstValueFrom } from 'rxjs';
import { switchMap, tap, timeout, map, defaultIfEmpty, filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CONSOLE_SHARED, ConsoleShared } from '../modules/providers/console-shared.provider';

interface AdminLog {
  'logScope': 'authChange';
  'dataLayer': {
    'project': 'aiii-bot-platform'; // '哪個 project', (aiii-bot-platform)
    'site': 'aiii-bot-platform'; // '部在哪個 hosting',(aiii-bot-platform)
    'channelKey': string; // '站台的 Bot basic ID', (需多撈一次資料)
    'deptKey': string; // '站台的 key'
    'uid': string; // 'users 下的 userId', (firebase auth id)
    'actScope': 'backstage' | 'lineLiff';
    'actSubScope': string; //  '模組名稱',
    'act': string;
    'actLabel': string;
    'actAt': string;
    'key': string;
    'subKey': string;
    'value': string;
    'mapping': {
      'docId': string; // '通常為模組下的 doc key',
      'loginChannel': 'line' | 'gmail';
    };
    'payload': any; //  '其他類型的輔助資料 (例如：新增了哪些用戶權限、移除了哪些用戶權限、其他模組關聯的 key …)'
  };
}

export enum DatabasePath {
  users = 'users',
  sites = 'sites',
  bots = 'bots',
}

export enum CallableApi {
  AddUser = 'addUserOnCall',
  DeleteUser = 'deleteUserOnCall',
  UpdateUser = 'updateUserOnCall',
  SetCustomClaims = 'setCustomClaimsOnCall',
  verifyToken = 'verifyTokenOnCall',
}

export enum AccessLevel {
  Visitor,
  shopUser,
  shopEditor,
  consoleEditor,
  consoleAdmin,
  aiiiEditor,
  aiiiAdmin,
}

export enum Role {
  Visitor = '無權限',
  shopUser = '站台客服者',
  shopEditor = '站台使用者',
  consoleEditor = '站台編輯者',
  consoleAdmin = '站台管理者',
  aiiiEditor = '平台編輯者',
  aiiiAdmin = '平台管理者',
}

export interface Site {
  _key: string;
  name: string;
  enable: boolean;
  consoleOption?: Array<any>;
}

export interface User {
  uid?: string;
  email: string | null | undefined;
  password?: string;
  displayName?: string;
  photoURL?: string;
  phoneNumber?: string;
  disabled?: boolean;
  loginAt?: Date;
  logoutAt?: Date;
  providerData?: any[];
  customClaims?: CustomClaims;
}

// 使用者權限
export interface CustomClaims {
  role: Role;
  accessLevel: AccessLevel;
  site?: string;
  siteName?: string;
  zoneM?: string;
  store?: string;
  storeId?: string;
  siteMap?: { [site: string]: { accessLevel: AccessLevel } };
  sites?: string[];
  organization?: string; // 群組選單用
}

export interface Event {
  category: string;
  action: string;
  label: any;
  value?: number;
  timestamp?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  redirectUrl = '';
  user$!: Observable<User>;
  uid = '';
  displayName = '';
  accessLevel: AccessLevel = 5;
  roles = [
    { accessLevel: AccessLevel.Visitor, role: Role.Visitor },
    { accessLevel: AccessLevel.shopUser, role: Role.shopUser },
    { accessLevel: AccessLevel.shopEditor, role: Role.shopEditor },
    { accessLevel: AccessLevel.consoleEditor, role: Role.consoleEditor },
    { accessLevel: AccessLevel.consoleAdmin, role: Role.consoleAdmin },
    { accessLevel: AccessLevel.aiiiEditor, role: Role.aiiiEditor },
    { accessLevel: AccessLevel.aiiiAdmin, role: Role.aiiiAdmin },
  ];

  constructor(
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public router: Router,
    public http: HttpClient,
    @Inject(CONSOLE_SHARED) private readonly cs: ConsoleShared
  ) {
    const authInstance = getAuth();
    setPersistence(
      authInstance,
      browserLocalPersistence
    )
      .then(() => {
        // console.log('auth Persistence ==>', environment.fireAuth.Persistence);
      });
    this.init();
  }

  private init() {
    this.user$ = this.afAuth.authState.pipe(
      tap(firebaseUser => {
        if (firebaseUser) {
          this.uid = firebaseUser.uid;
          this.displayName = firebaseUser.displayName!;
          sessionStorage.setItem('uid', this.uid || '');
        }
      }),
      switchMap(user => this.afs.doc<User>(`${DatabasePath.users}/${user!.uid}`).valueChanges()), 
      filter(user => user !== null),  // 使用 filter 過濾掉為 null 的值
      map(user => user as User), // 使用 map 來轉換為 User 類型
      tap(user => {
        if (user) {
          const accessLevelMap: { [site: string]: { accessLevel: AccessLevel } } = user?.customClaims?.siteMap || {};
          if (this.cs.site && this.cs.site in accessLevelMap) { // 過渡期，將預設權限寫進 site map
            this.accessLevel = accessLevelMap[this.cs.site]?.accessLevel || 0;
          } else {
            this.accessLevel = user?.customClaims?.accessLevel || 0;
          }
        }
      })
    ) as Observable<User>;
  }

  private async updateUserData({
    uid,
    email,
    displayName,
    photoURL,
    providerData,
  }: User) {
    console.log('updateUserData =>', {
      uid,
      email,
      displayName,
      photoURL,
      providerData,
    });
    const userRef = this.afs.doc<User>(`${DatabasePath.users}/${uid}`);
    const docSnapshot = await firstValueFrom(userRef.get());
    const userData = docSnapshot.data();
    let data: any;
    const customClaims = userData?.customClaims;
    const functions = getFunctions();

    if (userData === undefined) {
      const updatedCustomClaims = { ...customClaims, accessLevel: 0 };
      data = {
        displayName,
        photoURL,
        email,
        providerData: providerData!.map((obj) => Object.assign({}, obj)),
        loginAt: new Date(),
        customClaims: updatedCustomClaims,
      };
      console.log("data", data)
      return userRef.set(data);
    }
    if (userData?.customClaims?.accessLevel !== undefined && userData.customClaims.accessLevel as number === -1) {
      const updateUser = httpsCallable(functions, 'updateUserOnCall');
      updateUser({ uid: uid, disabled: false });
      const updatedCustomClaims = { ...customClaims, accessLevel: 0 };
      data = {
        displayName,
        photoURL,
        email,
        providerData: providerData!.map((obj) => Object.assign({}, obj)),
        loginAt: new Date(),
        customClaims: updatedCustomClaims,
      };
    } else {
      data = {
        displayName,
        photoURL,
        email,
        providerData: providerData!.map((obj) => Object.assign({}, obj)),
        loginAt: new Date(),
      };
      
    }

    return userRef.update(data);
  }

  async loginGoogle() {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    const result = await this.afAuth.signInWithPopup(provider);
    console.log('google login success:', result);
    const user = {
      uid: result.user?.uid,
      email: result.user?.email ?? undefined,
      displayName: result.user?.displayName ?? undefined,
      photoURL: result.user?.photoURL ?? undefined,
      providerData: result.user?.providerData,
    };
    return await this.updateUserData(user);
  }

  logout(): Promise<boolean> {
    const auth = getAuth();
    if (auth.currentUser) {
      return this.afs
        .doc(`${DatabasePath.users}/${auth.currentUser.uid}`)
        .set({ logoutAt: new Date() }, { merge: true })
        .then(() => signOut(auth))
        .then(() => this.router.navigate(['/']));
    } else {
      return Promise.reject(new Error('No authenticated user.'));
    }
  }
  
  // linkGoogle() {
  //   const provider = new auth.GoogleAuthProvider();
  //   provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  //   return this.afAuth.auth.currentUser
  //     .linkWithPopup(provider)
  //     .then((result) => {
  //       console.log('link to google success:', result);
  //     });
  // }

  // linkGoogle() {
  //   const auth = getAuth();
  //   const user = auth.currentUser;
  //   if (user) {
  //     const provider = new GoogleAuthProvider();
  //     provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  //     return linkWithPopup(user, provider).then((result) => {
  //       console.log('link to google success:', result);
  //     });
  //   } else {
  //     return Promise.reject(new Error('No authenticated user.'));
  //   }
  // }

  log(event: Event): Promise<void> {
    const auth = getAuth();
    const uid = this.uid || auth.currentUser?.uid;
    if (!uid) return Promise.reject(new Error('No authenticated user.'));
    event.timestamp = new Date();
    return this.afs
      .doc(`${DatabasePath.users}/${uid}/events/${event.timestamp.getTime()}`)
      .set({ ...event });
  }

  async logV2(event: {
    moduleName: AdminLog['dataLayer']['actSubScope'];
    action: AdminLog['dataLayer']['act'];
    docKey: AdminLog['dataLayer']['mapping']['docId'];
    additionalData?: {
      actLabel?: AdminLog['dataLayer']['actLabel'];
      key?: AdminLog['dataLayer']['key'];
      subKey?: AdminLog['dataLayer']['subKey'];
      value?: AdminLog['dataLayer']['value'];
      payload?: AdminLog['dataLayer']['payload'];
    };
  }): Promise<void> {
    const auth = getAuth();
    this.uid = this.uid || auth.currentUser!.uid;
    const result = await this.http.post(
      `${environment.cloudrun.api}/query-site-config`,
      { site: this.cs.site }).toPromise() as {
        accountId: string;
      };
  }

  //   const ClientIP = await this.getClientIP();

  //   const adminLog: AdminLog = {
  //     'logScope': 'authChange',
  //     'dataLayer': {
  //       'project': 'aiii-bot-platform',
  //       'site': 'aiii-bot-platform',
  //       'channelKey': result.accountId || '',
  //       'deptKey': this.cs.site,
  //       'uid': this.uid,
  //       'actScope': 'backstage',
  //       'actSubScope': event.moduleName,
  //       'act': event.action,
  //       'actLabel': event?.additionalData?.actLabel || event?.additionalData?.actLabel || '',
  //       'actAt': new Date().toISOString(),
  //       'key': event?.additionalData?.key || '',
  //       'subKey': event?.additionalData?.subKey || '',
  //       'value': event?.additionalData?.value || '',
  //       'mapping': {
  //         'docId': event.docKey,
  //         'loginChannel': /^U[\w]{32}$/.test(this.uid) ? 'line' : 'gmail'
  //       },
  //       'payload': _.merge({
  //         clientInfo: {
  //           userAgent: navigator.userAgent || '',
  //           language: navigator.language || '',
  //           vendor: navigator.vendor || '',
  //           host: location.host || '',
  //           pathname: location.pathname || '',
  //           protocol: location.protocol || '',
  //           search: location.search || '',
  //           href: location.href || '',
  //           clientIP: ClientIP,
  //         },
  //       }, event?.additionalData?.payload)
  //     }
  //   };

  //   await this.http.post(
  //     `${environment.cloudrun.adminLog}`, {
  //     log_content: JSON.stringify(adminLog),
  //     project_id: environment.logV2Config.projectId,
  //     topic_id: 'write-log-to-bigquery'
  //   }).toPromise();
  // }

  // createUser(user: User): Promise<any> {
  //   this.log({
  //     category: '帳號管理',
  //     action: '新增',
  //     label: user,
  //   });
  //   const addUser = firebase.functions().httpsCallable(CallableApi.AddUser);
  //   return addUser(user);
  // }
  // createUser(user: User): Promise<any> {
  //   this.log({
  //     category: '帳號管理',
  //     action: '新增',
  //     label: user,
  //   });
  //   const functions = getFunctions();
  //   const addUser = httpsCallable(functions, CallableApi.AddUser);
  //   return addUser(user).then((result) => {
  //     return result.data;
  //   });
  // }
  // updateUser(user: User): Promise<any> {
  //   this.log({
  //     category: '帳號管理',
  //     action: '編輯',
  //     label: user,
  //   });
  //   const updateUser = firebase
  //     .functions()
  //     .httpsCallable(CallableApi.UpdateUser);
  //   return updateUser(user);
  // }

  // updateUser(user: User): Promise<any> {
  //   this.log({
  //     category: '帳號管理',
  //     action: '編輯',
  //     label: user,
  //   });
  //   const functions = getFunctions();
  //   const updateUser = httpsCallable(functions, CallableApi.UpdateUser);
  //   return updateUser(user).then((result) => {
  //     return result.data;
  //   });
  // }

  // deleteUser(user: User): Promise<any> {
  //   this.log({
  //     category: '帳號管理',
  //     action: '刪除',
  //     label: user,
  //   });
  //   const deleteUser = firebase
  //     .functions()
  //     .httpsCallable(CallableApi.DeleteUser);
  //   return deleteUser({ uid: user.uid });
  // }

  // toggleUserDisabled(uid: string, toggle: boolean) {
  //   this.log({
  //     category: '帳號管理',
  //     action: toggle ? '停用' : '啟用',
  //     label: uid,
  //   });
  //   const updateUser = firebase
  //     .functions()
  //     .httpsCallable(CallableApi.UpdateUser);
  //   return updateUser({ uid: uid, disabled: toggle });
  // }

  // setCustomClaims(uid: string, customClaims: CustomClaims) {
  //   this.log({
  //     category: '帳號管理',
  //     action: `調整權限${customClaims}`,
  //     label: uid,
  //   });
  //   if (customClaims.site) {
  //     this.afs.doc(`${DatabasePath.users}/${uid}`).set({
  //       customClaims: {
  //         siteMap: {}
  //       }
  //     }, { merge: true }).catch(e => console.error(e));
  //   }
  //   return this.afs.doc(`${DatabasePath.users}/${uid}`).set({ customClaims }, { merge: true });
  // }

  // getSiteCollection(): Observable<Site[]> {
  //   return this.afs.collection<Site>(DatabasePath.sites).valueChanges();
  // }

  // async getClientIP(): Promise<string> {
  //   try {
  //     const result = await this.http.get(
  //       `${environment.cloudrun.api}/get-client-ip`).pipe(timeout(5000)).toPromise() as any;
  //     return result.IP || '';
  //   } catch (err) {
  //     console.error(err);
  //     return '';
  //   }
  // }

}
