import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { doc, getDoc, getFirestore, DocumentSnapshot } from 'firebase/firestore';
// import * as firebase from 'firebase';
interface AdminMap {
  [uid: string]: {
    displayName: string;
  };
}

interface UserEmail {
  uid: string;
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class AdminService {

  public adminMap: AdminMap = {};
  public allAdminsEmail: UserEmail[] = [];

  constructor(
    public firestore: AngularFirestore,
  ) {
  }

  async getAdminDataFromDB(uid: string, useLineUserData: boolean) {
    const adminDoc = await this.firestore.doc(`/users/${uid}`).ref.get() as unknown as DocumentSnapshot;

    // const getDisplayName = (doc: firebase.firestore.DocumentSnapshot) => {
    //   return doc.exists ? doc.get('displayName') || '未知' : '未知';
    // };

    const getDisplayName = (documentSnapshot: DocumentSnapshot) => {
      return documentSnapshot.exists! ? documentSnapshot.get('displayName') || '未知' : '未知';
    };
    
    if (adminDoc.exists!) {
      this.adminMap[uid].displayName = getDisplayName(adminDoc);
    } else if (useLineUserData) {
      const userDoc = await this.firestore.doc(`/lineUsers/${uid}`).ref.get() as unknown as DocumentSnapshot;
      this.adminMap[uid].displayName = getDisplayName(userDoc);
    } else {
      this.adminMap[uid].displayName = '未知';
    }

    return this.adminMap[uid];
  }

  getAdminData(uid: string, useLineUserData = false) {
    try {
      if (!uid) {
        throw Error('no uid');
      }
      if (!this.adminMap[uid]) {
        this.adminMap[uid] = {
          displayName: 'loading...',
        };
        this.getAdminDataFromDB(uid, useLineUserData).catch(err => console.error(err));
      }
      return this.adminMap[uid];
    } catch (err) {
      return {
        displayName: 'no uid',
      };
    }
  }

  async getAllAdminEmail() {
    if (!this.allAdminsEmail) {
      this.allAdminsEmail = [];
      const querySnapshot = await this.firestore.collection('users').get().toPromise();
      querySnapshot!.forEach((doc) => {
        const data = doc.data() as { email: string };
        const user = {
          uid: doc.id,
          email: data.email || ''
        };
        this.allAdminsEmail.push(user);
      });
    }
    return this.allAdminsEmail;
  }  

}
