import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, AccessLevel, User } from '../shared/services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  title = 'Aiii管理平台';
  authError = false;
  errorMessage: string = '';

  constructor(public authService: AuthService, public router: Router) {}

  ngOnInit() {
    this.authService.user$.pipe(untilDestroyed(this)).subscribe({
      next: (user: User) => {
        this.loginProcess(user);
      },
    });
  }

  loginGoogle() {
    this.authService
      .loginGoogle()
      .then(() => {
        this.authService.user$.subscribe({
          next: (user: User) => {
            this.loginProcess(user);
          },
        });
      })
      .catch((error) => {
        alert('請重新登入，或聯繫站台管理人員。');
        console.error(error);
        this.errorMessage = error;
        this.authError = true;
      });
  }

  private loginProcess(user: User | undefined): void {
    console.log('Initializing login process:', user);

    if (user) {
      let redirectUrl = '';
      const queryParams = {};

      // 下面的邏輯看起來很奇妙，需要找時間檢視是否合理，並改寫成可讀性較高的版本
      const latestUrl =
        !localStorage.getItem('lastestUrl') || !localStorage.getItem('lastestUrl')!.includes('login')
          ? `${localStorage.getItem('lastestUrl') || ''}`.trim().replace(/^\/$/g, '')
          : '';
      const regResult = latestUrl.match(/^\/console\/([\w]+)/);
      const latestSite = regResult ? regResult[1] : '';

      if (!!localStorage.getItem('queryParams')) {
        const search = new URLSearchParams(localStorage.getItem('queryParams')!);
        search.forEach((v, k) => {
          (queryParams as { [key: string]: string })[k] = v;
        });
      }
      // 嘗試獲取有權限的站台
      const site = (() => {
        let selectSite = '';
        const siteList: string[] = [];

        for (const rowSite of user?.customClaims?.sites || []) {
          if (user?.customClaims?.siteMap![rowSite]?.accessLevel! > 0) {
            siteList.push(rowSite);
          }
        }

        if (siteList.includes(latestSite)) {
          selectSite = latestSite;
        } else {
          selectSite = siteList[siteList.length > 0 ? siteList.length - 1 : 0] || '';
        }

        // 沒有存取權限
        if (selectSite === '') {
          redirectUrl = '/noaccess';
          console.log('redirectUrl => /noaccess');
          this.router.navigate([redirectUrl], { queryParams });
        }

        return selectSite || user.customClaims!.site;
      })();

      const accessLevel: AccessLevel =
        _.get(user?.customClaims, `siteMap.${site}.accessLevel`) || user.customClaims!.accessLevel;
      switch (accessLevel) {
        case AccessLevel.aiiiAdmin:
          redirectUrl = latestUrl || `/console/${environment.defaultAdminSite}`;
          break;

        case AccessLevel.aiiiEditor:
          redirectUrl = latestUrl || `/console/${environment.defaultAdminSite}`;
          break;

        case AccessLevel.consoleAdmin:
          redirectUrl = `${latestUrl}`.includes(site!) ? latestUrl : `/console/${site}`;
          break;

        case AccessLevel.consoleEditor:
          redirectUrl = `${latestUrl}`.includes(site!) ? latestUrl : `/console/${site}`;
          break;

        case AccessLevel.shopEditor:
          redirectUrl = `${latestUrl}`.includes(site!) ? latestUrl : `/console/${site}`;
          break;

        case AccessLevel.shopUser:
          redirectUrl = `${latestUrl}`.includes(site!) ? latestUrl : `/console/${site}`;
          break;

        case AccessLevel.Visitor:
          redirectUrl = '/noaccess';
          break;

        default:
          break;
      }

      console.log('redirectUrl => ', { accessLevel, redirectUrl, lastestUrl: latestUrl, site });
      this.router.navigate([redirectUrl], { queryParams });
    }
  }
}
