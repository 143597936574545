import { Directive, HostListener } from '@angular/core';

/**
 * 所有的 input[type=file] 的 click 都會清除 value
 *
 * @export
 * @class ANoneHashDirective
 */
@Directive({
  selector: 'input[type=file]',
})
export class InputFileDirective {
  @HostListener('click', ['$event'])
  onClick(event: PointerEvent & { target: { files?: File[]; value?: string; } }) {
    if (event.target?.files!.length > 0) {
      event.target.value = '';
    }
  }
}
