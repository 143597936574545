<div id="login">
  <div id="loginBox">
    <img width="300px" src="assets/images/Aiii_logo-08.png" />
    <h1>{{ title }}</h1>
    <button class="google" mat-stroked-button (click)="loginGoogle()">
      Google 登入
    </button>
    <!-- <span class="divider">------ 或 ------</span> -->
  </div>
</div>
