/**
 * @fileoverview 排序Array內的資料
 * @param value {Array} Collection Array
 * @param args {Array} 排列方式 [[欄位名稱], [排列方式]]
 * @example *ngFor="let item of filteredList | sortedBy: [['order', 'createdAt'], ['asc', 'desc']]"
 */

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'sortedBy'
})
export class SortedByPipe implements PipeTransform {

  transform(value: Array<any>, args?: Array<any>): Array<any> {
    if (args) {
      return _.orderBy(value, args[0], args[1]);
    }
    return _.orderBy(value, ['order'], ['asc']);
  }

}
