import { Directive, HostListener } from '@angular/core';

/**
 * 所有的不帶url的#連結 被點擊時不繼續動作
 *
 * @export
 * @class ANoneHashDirective
 */
@Directive({
  selector: 'a[href="#"]'
})
export class ANoneHashDirective {
  @HostListener('click', ['$event'])
  onClick(e: any) {
    e.preventDefault();
  }
}
