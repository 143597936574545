import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, InjectionToken } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthService } from './shared/services/auth.service';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field'; 
import * as firebase from 'firebase/app';
firebase.initializeApp(environment.firebase);

import { SharedMaterialModule } from './shared/shared-material.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NoaccessComponent } from './noaccess/noaccess.component';
import { ReactiveFormsModule, NG_VALIDATORS, Validator } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReportComponent } from './web/report/report.component';
import { CONSOLE_SHARED_PROVIDER } from './shared/modules/providers/console-shared.provider';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ReportComponent,
    NoaccessComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    HttpClientModule,
    SharedMaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    RouterModule.forRoot([
    { path: '', component: ReportComponent },
    { path: 'login', component: LoginComponent },
    { path: 'noaccess', component: NoaccessComponent },
    { path: 'home', component: ReportComponent },
    { path: 'web', loadChildren: () => import('./web/web.module').then(m => m.WebModule) },
    { path: 'console', loadChildren: () => import('./console/console.module').then(m => m.ConsoleModule) },
    { path: '**', component: ReportComponent }
    ]),
    SharedModule.forRoot(),
    ReactiveFormsModule
  ],

  providers: [AuthService, Title, CONSOLE_SHARED_PROVIDER],
  bootstrap: [AppComponent]
})
export class AppModule { }
