import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare let dataLayer: any[];

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {

  public oldPath = window.location?.pathname || '';

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!dataLayer) {
          dataLayer = [];
        }
        dataLayer.push({
          'event': 'Router Change',
          'page_location': `${event.url || ''}`,
          'page_referrer': `${this.oldPath || ''}`,
        });
        this.oldPath = event.url;
      }
    });
    
  }
}
