import { Pipe, PipeTransform } from '@angular/core';

/**
 * 取代字串
 */
@Pipe({
  name: 'replaceText',
})
export class ReplaceTextPipe implements PipeTransform {

  constructor() {
  }

  transform(value: string, args: [RegExp, string]): any {
    return value.replace(args[0], args[1]);
  }

}
