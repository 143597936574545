import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-noaccess',
  templateUrl: './noaccess.component.html',
  styleUrls: ['./noaccess.component.css']
})
export class NoaccessComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.user$.pipe(untilDestroyed(this)).subscribe();
  }

  logout() {
    this.authService.logout();
  }
}
